import { useCartReferenceStore } from "@components/LoadCart"
import { getGraphQLClient } from "@lib/graphql-client"
import { useQuery } from "@tanstack/react-query"
import { ChoicesCart } from "./helpers/types"

const { sdk } = getGraphQLClient()

type UseCartReturn = {
  data: null | ChoicesCart
  isLoading: boolean
}

export function useCart(): UseCartReturn {
  const cartReferenceStore = useCartReferenceStore()
  let enabled = false
  if (cartReferenceStore.reference !== null) {
    enabled = true
  }

  const { data, isLoading } = useQuery(
    ["cart", cartReferenceStore.reference],
    () => sdk.Cart({ reference: cartReferenceStore.reference }),
    {
      enabled,
    }
  )

  return {
    data: (data?.choicesCart as ChoicesCart) || null,
    isLoading,
  }
}

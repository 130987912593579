import React, { useState, useContext } from "react"

export type Giftcard = {
  amount: number
  giftcardNumber: string
  securityCode: string
}

type Discount = {
  id: string
  code: string
  amount: number
}

export type Address = {
  firstName: string
  lastName: string
  namePrefix: string
  postalCode: string
  city: string
  street: string
  country: string
  state: string
  houseNumber: string
  houseNumberSuffix: string
}

export interface Details {
  companyName: string | null
  emailAddress: string
  phoneNumber: string
  customerAddress: Address
  invoiceAddress: Address | null
  invoiceAddressSame: boolean
  newsletterSignup: boolean
}

interface State {
  orderId: number | null
  details: null | Details
  amount: null | number
  giftcard: null | Giftcard
  discount: null | Discount
  paymentMethod: any
  action: any
}

type InitArgs = { amount: number }
interface Update {
  setDetails: (details: Details) => void
  setOrderInfo: (args: InitArgs) => void
  setGiftcard: (giftcard: Giftcard) => void
  removeGiftcard: () => void
  setDiscount: (discount: Discount) => void
  removeDiscount: () => void
  setPaymentMethod: (paymentMethod: any) => void
  setAction: (action: any) => void
  clear: () => void
  setOrderId: (orderId: number) => void
  clearOrderId: () => void
}

export interface ContextType extends State, Update {}

const initialState = {
  orderId: null,
  details: null,
  amount: null,
  giftcard: null,
  discount: null,
  paymentMethod: null,
  action: null,
}

const Context = React.createContext<ContextType>({
  ...initialState,
  setDetails: (args) => null,
  setOrderInfo: (args) => null,
  setGiftcard: (args) => null,
  removeGiftcard: () => null,
  setDiscount: (args) => null,
  removeDiscount: () => null,
  setPaymentMethod: (args) => null,
  setAction: (args) => null,
  clear: () => null,
  setOrderId: () => {},
  clearOrderId: () => {},
})

interface Props {}
export const CheckoutContextProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useState<State>({
    ...initialState,
  })

  const setDetails = (details: Details) =>
    setState((state) => ({ ...state, details }))
  const setOrderInfo = ({ amount }: InitArgs) =>
    setState((state) => ({ ...state, amount }))
  const setGiftcard = (giftcard) =>
    setState((state) => ({ ...state, giftcard }))
  const removeGiftcard = () =>
    setState((state) => ({ ...state, giftcard: null }))
  const setDiscount = (discount) =>
    setState((state) => ({ ...state, discount }))
  const removeDiscount = () =>
    setState((state) => ({ ...state, discount: null }))
  const setPaymentMethod = (paymentMethod) =>
    setState((state) => ({ ...state, paymentMethod }))
  const setAction = (action) => setState((state) => ({ ...state, action }))
  const clear = () => setState({ ...initialState })
  const setOrderId = (orderId: number) =>
    setState((state) => ({ ...state, orderId }))
  const clearOrderId = () => setState((state) => ({ ...state, orderId: null }))

  return (
    <Context.Provider
      value={{
        ...state,
        setDetails,
        setOrderInfo,
        setGiftcard,
        removeGiftcard,
        setDiscount,
        removeDiscount,
        setPaymentMethod,
        setAction,
        clear,
        setOrderId,
        clearOrderId,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export function useCheckout() {
  const ctx = useContext(Context)
  if (typeof ctx === "undefined") {
    throw new Error(
      "Can only use checkout context within a CheckoutContext Provider"
    )
  }
  return ctx
}
